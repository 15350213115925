export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Shirou Day Online",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11772378/ShirouDayOnline_2.0.3_(WebGL)/index.html",
    domain: "shirouday.online",
    gaId: "G-6WCJTGXZPP",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
